import React from 'react'
import { NavLink } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

function Card({post}) {
  return (
    <div>
      <NavLink to={`/${post.url}`}>
        <div >
            <LazyLoadImage className='object-contain' src={post.imageUrl} alt="img" height="auto"  width="auto"/>
       <div>
        </div>
    </div>
      <div className='font-semibold text-sm md:text-lg text-center sm:-mt-4 xs:mt-8 text-indigo-800'>{post.title}</div>
      <div className='font-semibold text-sm md:text-lg text-center text-indigo-800'>{post.subtitle}</div>
      </NavLink>
    </div>
  )
}

export default Card
import React, { useEffect, useRef } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

function Invoice() {
    const navigate = useNavigate();
    const printRef = useRef(null);
    const location = useLocation();

    const {personalInfo, accountInfo, invoiceToInfo, invoiceInfo, invoiceName, rows, amount, imageUrl} = location.state;

    let date = '';
    if(invoiceInfo.date){
        var today = new Date(invoiceInfo.date);
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "July", "Aug", "Sep", "Oct", "Nov", "Dec"];
        let month = months[today.getMonth()];
        date =  month + ' ' + today.getDate() + ', '  + today.getFullYear();
    }
   
    const handleDownloadPdf = async () => {
        const element = printRef.current;
        if (!element) {
          return;
        }
    
        const canvas = await html2canvas(element, {
          scale: 2,
        });
        const data = canvas.toDataURL("image/png");
    
        const pdf = new jsPDF({
          orientation: "portrait",
          unit: "px",
          format: "a4",
        });
    
        const imgProperties = pdf.getImageProperties(data);
        const pdfWidth = pdf.internal.pageSize.getWidth();
    
        const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
    
        pdf.addImage(data, "PNG", 0, 0, pdfWidth, pdfHeight);
        pdf.save(`${invoiceName}.pdf`);
      };

      const handleCreateInvoice = () => {
        navigate('/admin/createinvoice');
      }

      const handleEditInvoice = () => {
        navigate('/admin/editinvoice', {state:{
            personalInfo: personalInfo,
            accountInfo: accountInfo,
            invoiceToInfo: invoiceToInfo,
            invoiceInfo: invoiceInfo,
            invoiceName: invoiceName,
            rows: rows,
            amount: amount
        }});
      }

    useEffect(() => {
        const auth = localStorage.getItem("auth");
        if(auth !== "Logged In")
        {
          navigate("/admin/login");
        }
    }, []);
  return (
    <div>
    <div className="mt-2 flex w-9/12 mx-auto justify-evenly">
            <button
            onClick={handleCreateInvoice}
            className="flex items-center bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 transition duration-300"
          >
            New Invoice
          </button>
          <button
            onClick={handleEditInvoice}
            className="flex items-center bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 transition duration-300"
          >
            Edit Invoice
          </button>
          <button
            onClick={handleDownloadPdf}
            className="flex items-center bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 transition duration-300"
          >
            Download PDF
          </button>
          
        </div>
    <div ref={printRef} className='w-6/12 mx-auto mt-10 py-2 border-2 px-5 mb-10'>
    <div>
        <div className='flex justify-between'>
            <div className=' flex flex-col justify-start '>
                {/* <div className='text-gray-500 font-bold text-lg'>From</div> */}
                <div className='font-bold text-md'>{personalInfo.name}</div>
                { personalInfo.address1 && <div className='text-gray-500 text-sm'>{personalInfo.address1}</div>}
                { personalInfo.address2 && <div className='text-gray-500 text-sm'>{personalInfo.address2} - {personalInfo.address3}</div>}
                { personalInfo.email && <div className='text-gray-500 text-sm'>Email: {personalInfo.email}</div>}
            </div>
        <div>
            <div className='font-bold text-md text-gray-700'>INVOICE</div>
            <div className='font-bold text-sm text-gray-500'>{invoiceInfo.invoiceNumber}</div>
        </div>
        </div>
        <div className='mt-6 flex justify-between'>
            <div>
                <div className='uppercase text-gray-700 font-bold text-sm'>Invoice To</div>
                <div className='font-semibold text-md mt-1'>{invoiceToInfo.inv_name}</div>
                { invoiceToInfo.inv_address1 && <div className='text-gray-500 text-sm'>{invoiceToInfo.inv_address1}</div>}
                { invoiceToInfo.inv_address2 && <div className='text-gray-500 text-sm'>{invoiceToInfo.inv_address2} - {invoiceToInfo.inv_address3}</div>}
                { invoiceToInfo.inv_gst && <div className=' mt-2 text-sm text-gray-500'>GSTIN : {invoiceToInfo.inv_gst}</div>}
                { invoiceToInfo.inv_tin && <div className=' text-sm text-gray-500'>TIN : {invoiceToInfo.inv_tin} </div>}
                { invoiceToInfo.inv_cin && <div className=' text-sm text-gray-500'>CIN : {invoiceToInfo.inv_cin}</div>}
                { invoiceToInfo.inv_pan && <div className='text-sm text-gray-500'>PAN : {invoiceToInfo.inv_pan}</div>}
                { invoiceToInfo.inv_email && <div className='text-sm text-gray-500 mt-2'>Email : {invoiceToInfo.inv_email}</div>}
            </div>
            <div className='font-bold text-sm'>
                <div className=' uppercase text-right'>Date</div>
                { date && <div className='text-gray-500'>{date}</div>}
            </div>
            
        </div>
        
        <div className='overflow-hidden mt-6'>
        <table className=" w-full mx-auto text-center text-sm font-light">
                
                <thead className="text-md uppercase">
                    <tr className='flex justify-between'>
                        <th className="w-[280px] text-left">Description</th>
                        <th className="w-[150px]" >Rate</th>
                        <th className="w-[50px]" >Quantity</th>
                        <th className="w-[150px]" >Amount</th>
                    
                    </tr>
                    <tr className='flex justify-between border-b-2 border-gray-400 mt-4'></tr>
                </thead>

                    <tbody >
                {
                   
                    rows && rows.length > 0 && rows.map((row, index) => (
                        <div>
                        <tr className="flex justify-between text-md mt-1 font-light">
                            <td className="w-[280px] text-left">{row.desc}</td>
                            <td className="w-[150px] ">{row.rate}</td>
                            <td className="w-[50px] ">{row.qty}</td>
                            <td className="w-[150px]">{row.amount}</td>
                        </tr>
                        <tr className="flex justify-between text-md mt-4 border-b-2 border-dashed"></tr>
                        </div>)
                        
            )   
           } 
           </tbody> 
            
           </table>
</div>
        </div>
        
        <hr className="w-full border-[1.2px] border-gray-400 mt-2"/>
        { <div className='flex justify-between items-end'>
            <div className=' mt-1 px-2 text-md font-semibold rounded-md uppercase '>Total </div>
            <div className=' mt-1 px-2 text-md font-extrabold rounded-md '>{amount} INR</div>
        </div>}
        <hr className="w-full border-[1.2px] mt-4 border-gray-400 "/>
        <div className='flex justify-between'>
        { accountInfo.accountNumber && <div>
        <div className='font-bold text-md mt-6 text-gray-700 uppercase'>Account Information</div>
        <div className=' text-sm mt-4'>
            <div>Bank Name : <span className='font-bold '>{accountInfo.bankName}</span></div>
            <div>Name : <span className='font-bold '>{accountInfo.accountName}</span></div>
            <div>Account Number : <span className='font-bold '>{accountInfo.accountNumber}</span></div>
            <div>Account Type : <span className='font-bold '>{accountInfo.accountType}</span></div>
            <div>IFSC Code : <span className='font-bold '>{accountInfo.ifsc}</span></div>
            { accountInfo.branch && <div>Branch : <span className='font-bold '>{accountInfo.branch}</span></div>}
            { accountInfo.pan && <div>PAN : <span className='font-bold '>{accountInfo.pan}</span></div>}
            <br/>
            { accountInfo.upi && <div>GPay : <span className='font-bold '>{accountInfo.upi}</span></div>}
        </div>
        </div>}
        <div className='flex flex-col mt-8'>
            { imageUrl && <img className='w-[210px] h-[50px]' src={imageUrl} alt='sign'/>}
            <div className=' text-gray-600 mt-10'>
                <div>Thank you for your business!</div>
            </div>
      </div>
        </div>
      
        
        </div>
    </div>
  )
}

export default Invoice
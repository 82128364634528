import React from 'react'
import { IoPlayCircleOutline } from 'react-icons/io5'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { NavLink } from 'react-router-dom'

const InstaVideoCard1 = ({url, imageUrl, title, subtitle}) => {
  return (
    <div>
    
        <NavLink to={`${url}`} target='_blank' >
        <div className='relative pointer-events-auto'>
        <LazyLoadImage className='object-contain' src={imageUrl} alt="img" height="auto"  width="auto"/>
            <div className='absolute text-white top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'>
                   <IoPlayCircleOutline size={50} />
              </div>
            </div>
            <div className='font-semibold text-sm md:text-lg text-center sm:-mt-4 xs:mt-8 text-indigo-800'>{title}</div>
            <div className='font-semibold text-sm md:text-lg text-center text-indigo-800'>{subtitle}</div>
        </NavLink>
        
    </div>
  )
}

export default InstaVideoCard1
import React from 'react';
import { useEffect } from "react";
import { Link } from 'react-router-dom';
import axios from "axios";
import { Download } from 'lucide-react';
import confetti from 'canvas-confetti';

function IronRichRecipes() {

  const getGeoInfo = async () => {
    const data = new FormData();
    axios.get('https://ipapi.co/json/').then((response) => {
        let res = response.data;
        console.log(res);
        data.append('ip', res.ip);
        data.append('city', res.city);
        data.append('state', res.region);
        data.append('country', res.country_name);

        //add into firebase

        //axios.post(`${baseUrl}/insertuser7month`, data );
    }).catch((error) => {
        console.log(error);
    });     
};

const handleClick = () => {
  confetti({
      particleCount: 400,
      spread:80
     });
}
  useEffect(() => {
    window.scroll(0,0);
  }, []);

  return (
    <div>
    <p className='p-2'><strong><Link to="/">Home</Link> </strong> / <strong><Link to="/babyfoodcharts">Food Charts</Link> </strong> / Iron Rich Recipes</p>
         <div className='p-4'>
         <div className='flex flex-col items-center'>
            <h2 className='text-xl poppins-medium'>10 Iron Rich Recipes</h2>
            <div className='bg-pink-600 mt-4 px-4 py-1 font-semibold text-center rounded-lg text-white hover:scale-110 transition duration-300 ease-in'>
                <a 
                    href="/Iron Rich Recipes.pdf"
                    download="Iron Rich Recipes"
                    target="_blank"
                    rel="noreferrer"
                >
                    <button onClick={handleClick}>
                    <div className='flex gap-x-2'>
                    <Download/>
                    <div>Download PDF</div>
                    </div></button>
                </a>
               
            </div>
            <div className='text-gray-600 text-xs mt-4'>We are using secure connection to download.</div>
        </div>
        </div>  
    </div>
  )
}

export default IronRichRecipes
import React, { useEffect, useState } from 'react'
import "./YoutubeEmbed.css";
import YoutubeEmbed from './YoutubeEmbed';
import Page1 from './Page1';
import PopularCard from './PopularCard';
import ProductCard from './ProductCard';
import Faq from './Faq';
import "./Page1.css";
import { NavLink } from 'react-router-dom';
import "./HomePage.css"
import MainPageSlider from './MainPageSlider';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../config/firebase';

function HomePage() {
    const [isExpanded1, setExpanded1] = useState(false)
    const [isExpanded2, setExpanded2] = useState(false)
    const [isExpanded3, setExpanded3] = useState(false)
    const [isExpanded4, setExpanded4] = useState(false)

    const [instaCount, setInstaCount] = useState('67K');
    const [youtubeCount, setYoutubeCount] = useState('2000');
    const [instaReelCount, setInstaReelCount] = useState('9M');

    const getSocialMediaCount = async () => {
      try{
        //setLoading(true);
    
        const codeCollectionRef = collection(db, "Social Media Count");
    
        const data = await getDocs(codeCollectionRef);
        const filteredData = data.docs.map((doc) => ({...doc.data(), id:doc.id}));

        setInstaCount(filteredData[0].instacount);
        setYoutubeCount(filteredData[0].youtubecount);
        setInstaReelCount(filteredData[0].instareelcount);
    
        localStorage.setItem('socialmediacount', JSON.stringify(filteredData[0]));
    
        //setLoading(false);
    }
    catch(er){
        console.log(er);
    }
    }

    useEffect(() => {
      let data = JSON.parse(localStorage.getItem('socialmediacount'));

      if(data == null || !data)
      {
        getSocialMediaCount();
      }
      else{
        setInstaCount(data?.instacount);
        setYoutubeCount(data?.youtubecount);
        setInstaReelCount(data?.instareelcount);
      }
    }, []);

    const myStyle = {
      backgroundImage:
          "url('../../images/bg_1.png')",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
  };

  return (
   <div style={myStyle}>
      {/* <MainPageSlider/> */}
      <div className='py-2 w-10/12 mx-auto' >
        <Page1/>
      </div>

      <div className=' mt-10 py-2'>
          <div className='flex flex-col md:flex-row justify-evenly w-full px-2 md:px-0 md:w-10/12 mx-auto py-8 mt-4 gap-x-1 gap-y-12 '>
          
          <div className='px-2 text-center md:text-left '>
          <div className='text-3xl font-bold text-center uppercase  '>How to Choose Pre-school </div>
          <div className='poppins-regular'>
          {/* <div className='text-lg mt-8  leading-8'>

          Choosing the right preschool for your child is an important decision that sets the foundation for their early learning and development. This guide will help you navigate key factors such as curriculum, teacher qualifications, class size, safety measures, and overall environment. You'll also learn what to look for during a school visit, how to evaluate different teaching approaches, and tips for ensuring a smooth transition for your little one. Whether you're seeking a play-based program, Montessori method, or traditional preschool, this article will equip you with the knowledge to make the best choice for your child’s early education journey.
          </div> */}
          <div className='  text-xl mt-6'>Points to Consider Before Deciding on a Preschool for Your Children:</div>
          <div className='mt-4 text-lg leading-8 poppins-regular p-2 rounded-md'>
            <p className='mt-2 '>✅ School Distance </p>
            <p className='mt-2'>✅ Safety & Hygiene </p>
            <p className='mt-2'>✅ Child & Teacher Ratio</p>
            <p className='mt-2'>✅ Curriculum & Teaching Method</p>
            <p className='mt-2'>✅ Infrastructre & Environment</p> 
          </div>
          </div>
          </div>
          <div className='max-md:hidden'>
           
            <iframe width="560" height="315" className='rounded-lg'
              src="https://www.youtube.com/embed/7CT2BEuF1gA?mute=1" 
              title="YouTube video player" 
 
              ></iframe>
            </div>
            <div className='md:hidden'>
              <YoutubeEmbed embedId='7CT2BEuF1gA?mute=1' />
            </div>
          </div>
          </div>


          <div className='color shadow-xl shadow-gray-400'>
          <div className='w-10/12 mx-auto flex justify-center p-6 text-white text-3xl text-center googlefontpoppins'>Helping The New Moms Journey</div>
            <div className='w-10/12 mx-auto grid xxs:grid-cols-1 xs:grid-cols-1 sm:grid-cols-1 md:grid-cols-5 lg:grid-cols-5 xl:grid-cols-5 2xl:grid-cols-5 3xl:grid-cols-5 place-items-center gap-y-4 gap-x-4 mb-10 p-10'>
            <div className='flex flex-col items-center text-white gap-y-2 p-4'>
              <div className='text-5xl font-extrabold text-orange-300'>{instaCount}+</div>
              <div className='text-2xl googlefontpoppins text-white'>Followers</div>
              <NavLink className='text-2xl hover:underline hover:text-indigo-400 googlefontpoppins text-white' to={'https://www.instagram.com/mommy_sanju/'} target="_blank">on Instagram</NavLink>
            </div>
            <div className='border-r-2 border-white h-full'></div>
            <div className='flex flex-col items-center text-white gap-y-2 p-4'>
              <div className='text-5xl font-extrabold text-orange-300'>{instaReelCount}+</div>
              <div className='text-2xl googlefontpoppins text-white'>Reel Views</div>
              <NavLink className='text-2xl hover:underline hover:text-indigo-400 googlefontpoppins text-white' to={'https://www.instagram.com/p/C0D5z_RSK-_/'} target="_blank">on Instagram</NavLink>
            </div>
            <div className='border-r-2 border-white h-full'></div>
            <div className='flex flex-col items-center text-white gap-y-2 p-4'>
              <div className='text-5xl font-extrabold text-orange-300'>{youtubeCount}+</div>
              <div className='text-2xl googlefontpoppins text-white'>Subscribers</div>
              <NavLink className='text-2xl hover:underline hover:text-indigo-400 googlefontpoppins text-white' to={'https://www.youtube.com/@MommyGK'} target="_blank">on YouTube</NavLink>
            </div>
            </div>
          </div>


      <div className='py-16'>
            <div className='text-2xl md:text-3xl uppercase text-black flex justify-center gap-x-2 font-bold '>Important <span className='text-red-700'>Tips</span></div>
             <div className='flex flex-wrap gap-x-4 mt-14 justify-evenly'>
             <div className='w-[320px] h-[320px] -mb-[100px]'>
                <YoutubeEmbed embedId='XFYW6NP7Hw8' />
              </div>
             <div className='w-[320px] h-[320px] -mb-[100px]'>
                <YoutubeEmbed embedId='T51chIH2NLI' />
              </div>
              <div className='w-[320px] h-[320px] -mb-[100px]'>
                <YoutubeEmbed embedId='1h-Buyvan-M' />
              </div>
            </div>
      </div>


          {/* <div className='py-8'>
          <div className='w-full md:w-10/12 mx-auto'>
            <div className='text-2xl md:text-3xl text-black  poppins-semibold justify-center flex gap-x-2 text-center md:text-left'>Popular <span className='text-green-700'> Recipe Posts</span></div>
            <div className='flex flex-wrap gap-x-10 md:gap-x-0 gap-y-10 justify-evenly mt-4 py-5'>
              <PopularCard url={'https://www.youtube.com/watch?v=4S02boyMtWA'} imageUrl={'../../images/carrot11.webp'} title={'Carrot Recipe'} color={3}/>
              <PopularCard url={'https://www.youtube.com/watch?v=T51chIH2NLI'} imageUrl={'../../images/banana_puree.webp'} title={'First Week Food'} color={1}/>
            </div>
          </div>
          </div> */}

          <div className=' py-8'>
          <div className='flex flex-col md:flex-row justify-evenly w-full px-2 md:px-0 md:w-10/12 mx-auto py-8 mt-4 gap-x-1 gap-y-12 '>
          
          <div className='px-2 text-center md:text-left'>
          <div className='text-3xl font-bold text-center uppercase text-blue-600 poppins-medium'>Pure love in <span className='text-green-600'>every spoonful</span></div>
          <div className='poppins-regular'>
          <div className='text-xl mt-8  leading-8 font-semibold'>

            Breakfast Recipes / Healthy Recipes 
          </div>
           <div className='mt-6 text-xl leading-8 poppins-medium'>
            <p>1. Oats Pancake</p>
            <p>2. Moong Uttapam </p>
            <p>3. Paneer Bhurji</p>
            <p>4. Mango Pancake</p>
            <p>5. Gujarati Handvo</p> 
          </div>
          </div>
          </div>
          <div className='max-md:hidden'>
           
            <iframe width="560" height="315" className='rounded-lg'
              src="https://www.youtube.com/embed/ck8ZZ7f61fQ?autoplay=1&mute=1" 
              title="YouTube video player" 
 
              ></iframe>
            </div>
            <div className='md:hidden'>
              <YoutubeEmbed embedId='ck8ZZ7f61fQ?autoplay=1&mute=1' />
            </div>
          </div>
          </div>

          <div>
            <div className='w-10/12 mx-auto text-lg mt-10 py-8'>
              <div className='text-2xl md:text-3xl uppercase text-green-700 flex justify-center gap-x-2 font-bold'>Baby Care <span className='text-black'>Checklist</span></div>
              <div className='flex flex-wrap gap-x-10 md:gap-x-0 gap-y-10 justify-evenly mt-14 py-5'>
              <ProductCard url={'/newmomchecklist'} imageUrl={'../../images/newmomchecklist.png'}/>
              <ProductCard url={'/foodplatecheklist'} imageUrl={'../../images/foodplate.png'}/>
              <ProductCard url={'/proofingheklist'} imageUrl={'../../images/babyproofing.png'}/>
              <ProductCard url={'/toys'} imageUrl={'../../images/toys6to12.png'}/>
              </div>
            </div>
            </div>
          <div>
          
          </div>
          <div className=' mt-10 py-8'>
          <div className='flex flex-col md:flex-row justify-evenly w-full px-2 md:px-0 md:w-10/12 mx-auto py-8 mt-4 gap-x-1 gap-y-12 '>
          
          <div className='px-2 text-center md:text-left'>
          <div className='text-3xl font-bold text-center uppercase text-blue-600'>Tiny trends & <span className='text-green-600'>big smiles</span></div>
          <div className='poppins-regular'>
          <div className='text-lg mt-8  leading-8'>

            Dress your little one in love, comfort, and endless possibilities. Their tiny outfits are the fabric of cherished memories, stitched with care and sprinkled with joy." 👶👕✨ #TinyThreadsOfLove #BabyFashionStatements
          </div>
           <div className='mt-6 text-lg leading-8'>
            Wrap your bundle of joy in snuggly warmth and adorable style. From tiny socks to soft onesies, every garment is a hug waiting to happen, a smile waiting to bloom." 🍼👶💕 #CuteAndCozy #BabyFashionJoy
          </div>
          </div>
          </div>
          <div className='max-md:hidden'>
           
            <iframe width="560" height="315" className='rounded-lg'
              src="https://www.youtube.com/embed/3NZ-gHKMEDQ?autoplay=1&mute=1" 
              title="YouTube video player" 
 
              ></iframe>
            </div>
            <div className='md:hidden'>
              <YoutubeEmbed embedId='3NZ-gHKMEDQ?autoplay=1&mute=1' />
            </div>
          </div>
          </div>
     
          
          {/* <div className='bg-gradient-to-r from-stone-50 to-blue-50'>
          <div className='w-10/12 mx-auto text-2xl py-8 mt-10'>
            <div className='xs:text-xl sm:text-xl md:text-3xl uppercase text-red-700 flex justify-center gap-x-2 font-bold '>Posts at <span className='text-green-700'>Instagram</span></div>
            <div className='mt-14'>
              <Slider1/>
            </div>
          </div>
          </div> */}
          
          <div id='faq' className='w-9/12 mx-auto py-8 flex flex-col gap-y-4 mt-10'>
            <div className='text-3xl poppins-regular flex justify-center text-center'>Frequently asked questions</div>
            <div className='border-b-2 border-stone-200 mt-8'></div>
              <Faq isExpanded={isExpanded1} setExpanded={setExpanded1} isLastFaq={false} title={"When to start solid food for baby?"} desc={"Solid food can be started 6 month onwards."}/>
              <Faq isExpanded={isExpanded2} setExpanded={setExpanded2} isLastFaq={false} title={"Do you have a mobile application?"} desc={"Currently we don't have any mobile application."}/>
              <Faq isExpanded={isExpanded3} setExpanded={setExpanded3} isLastFaq={false} title={"Do you have recipe videos?"} desc={"Yes we have recipe videos available on our youtube channel and link is available on footer."}/>
              <Faq isExpanded={isExpanded4} setExpanded={setExpanded4} isLastFaq={true} title={"Is 6 month baby food chart enough?"} desc={"Yes, It is sufficient and If you have any further questions then please feel free to send us a message using Contact us option."}/>
          </div>
          </div>
  )
}

export default HomePage
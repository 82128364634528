import React, { useEffect, useState } from 'react'
import InstaVideoCard from './InstaVideoCard'
import { CATEGORY_12_PLUS_MONTHS, CATEGORY_6_TO_12_MONTHS, CATEGORY_ALL , IRON_RICH_RECIPES} from '../utils/contants';
import ShimmerUICard from './ShimmerUICard';
import InstaVideoCard1 from './InstaVideoCard1';

const IronRichRecipesVideos = () => {
  const [loading, setLoading] = useState(false);
  const [posts, setPosts] = useState([]);
  const [instaVideos, setInstaVideos] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [checked, setchecked] = useState(true);

  const fetchVideos = async() => {
    try
    {
        setLoading(true);

        let instaVideos = JSON.parse(localStorage.getItem('recipe-videos'));
        instaVideos = instaVideos.filter(x => x.category === IRON_RICH_RECIPES);

        setInstaVideos(instaVideos);  
        setPosts(instaVideos);
        setFilterData(instaVideos);

        setLoading(false);
    }
    catch(er)
    {
       console.log(er);
    }
  }

  const handleClick = (category) => {
    
    setLoading(true);
    setSearchText('');

    let posts = [];
    if(category === 'All')
    {
      setchecked(true);
       posts = instaVideos;
    }
    else{
      setchecked(false);
      posts = instaVideos.filter(x => x.category === category);
    }
      
    setFilterData(posts);

    setLoading(false);
  }
  
  const handleSearch = (text) => {
    setSearchText(text);
    const data = posts.filter(x => x.title.toLowerCase().includes(text.toLowerCase()));
    setFilterData(data);
  }
  useEffect(() => {
    fetchVideos();
    window.scroll(0,0);
  },[]);

  return (
    <div className='w-full md:w-10/12 mx-auto text-lg mt-4'>
          <div className='text-xl md:text-3xl uppercase text-blue-700 font-mono font-bold mb-8 text-center'>Iron Rich Recipe Videos</div>
          {
              
              loading ? (<ShimmerUICard/>) : (<div className='grid grid-cols-2 place-items-center gap-y-4 mb-10 md:hidden'>
            {
              filterData && filterData.length > 0 && filterData.map((post) => (
                <InstaVideoCard1 key={post.title} url={post.InstaUrl} imageUrl={post.imageUrl} title={post.title} subtitle={post.subtitle}/>
              ))
            }
            </div>)
            }
            {
              
              loading ? (<ShimmerUICard/>) : (<div className='grid grid-cols-4 mb-10 max-md:hidden'>
            {
              filterData && filterData.length > 0 && filterData.map((post) => (
                <InstaVideoCard1 key={post.title} url={post.InstaUrl} imageUrl={post.imageUrl} title={post.title} subtitle={post.subtitle}/>
              ))
            }
            </div>)
            }
  </div>
  )
}

export default IronRichRecipesVideos
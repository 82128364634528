import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import { addDoc, collection, deleteDoc, doc, getDocs, updateDoc } from 'firebase/firestore';
import { db } from '../../config/firebase';
import { useDispatch, useSelector } from 'react-redux';

const EditInvoice = () => {

  const location = useLocation();



  const [invoiceName, setInvoiceName] = useState('');
  const [amount, setAmount] = useState(0);

  // personal information
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [address1, setAddress1] = useState('');
  const [address2, setAddress2] = useState('');
  const [address3, setAddress3] = useState('');
  const [phone, setPhone] = useState('');

  // account information
  const [accountName, setAccountName] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [bankName, setBankName] = useState('');
  const [accountType, setAccountType] = useState('');
  const [branch, setBranch] = useState('');
  const [upi, setUpi] = useState('');
  const [ifsc, setIfsc] = useState('');
  const [pan, setPan] = useState('');

  // invoice to information
  const [inv_name, setInv_Name] = useState('');
  const [inv_email, setInv_Email] = useState('');
  const [inv_address1, setInv_Address1] = useState('');
  const [inv_address2, setInv_Address2] = useState('');
  const [inv_address3, setInv_Address3] = useState('');
  const [inv_phone, setInv_Phone] = useState('');
  const [inv_gst, setInv_Gst] = useState('');
  const [inv_tin, setInv_Tin] = useState('');
  const [inv_pan, setInv_Pan] = useState('');
  const [inv_cin, setInv_Cin] = useState('');

  // invoice details
  const [invoiceNumber, setInvoiceNumber] = useState('');
  const [date, setDate] = useState('');


  // item details
  const [rows, setRows] = React.useState([{ desc: '', rate: '', qty: '', amount: 0 }]);
  const handleInputChange = (e, index) => {
    const values = [...rows];
    if (e.target.name === 'desc') {
      values[index].desc = e.target.value;
    } if (e.target.name === 'rate') {
      values[index].rate = e.target.value;
      values[index].amount = values[index].qty * values[index].rate;
    } if (e.target.name === 'quantity'){
        values[index].qty = e.target.value;
        values[index].amount = values[index].qty * values[index].rate;
    }

    setRows(values);
    AddTotal();
  };

  const AddTotal = () => {
    var total = 0;
    for(let i = 0; i < rows.length; i++){
        total += parseInt(rows[i].amount);
    }

   setAmount(total);
  }

  const RemoveTotal = (amt) => {
    let total = amount;

    total -= amt;
    setAmount(total);
  }

  const handleAddRow = () => {
    setRows([...rows, { desc: '', rate: '', qty: '', amount: 0 }]);
    AddTotal();
  };

  const handleDeleteRow = (index, e) => {
    setRows(rows.filter((v, i) => i !== index));
    RemoveTotal(rows[index].amount);
  }

    const navigate = useNavigate();

    const handleCreateInvoice = () => {
        const personalInfo = {
            name: name,
            email: email,
            address1: address1,
            address2: address2,
            address3: address3,
            phone: phone
        }

        const accountInfo = {
            accountName: accountName,
            accountNumber: accountNumber,
            bankName: bankName,
            accountType: accountType,
            upi: upi,
            ifsc: ifsc,
            pan: pan,
            branch: branch
        }

        const invoiceToInfo = {
            inv_name: inv_name,
            inv_email: inv_email,
            inv_address1: inv_address1,
            inv_address2: inv_address2,
            inv_address3: inv_address3,
            inv_phone: inv_phone,
            inv_gst: inv_gst,
            inv_tin: inv_tin,
            inv_pan: inv_pan,
            inv_cin: inv_cin
        }

        const invoiceInfo = {
            invoiceNumber: invoiceNumber,
            date: date
        }

        navigate('/admin/invoice', {state:{
            personalInfo: personalInfo,
            accountInfo: accountInfo,
            invoiceToInfo: invoiceToInfo,
            invoiceInfo: invoiceInfo,
            invoiceName: invoiceName,
            rows: rows,
            amount: amount
        }});
    }
    const inv_PersonalData_CollectionRef = collection(db, "Invoice_PersonalData");
    const inv_AccountData_CollectionRef = collection(db, "Invoice_AccountData");

    const handleSave = async () => {
        await addDoc(inv_PersonalData_CollectionRef, {
            name: name,
            email: email,
            address1: address1,
            address2: address2,
            address3: address3,
            phone: phone
          });

          await addDoc(inv_AccountData_CollectionRef, {
            accountName: accountName,
            accountNumber: accountNumber,
            bankName: bankName,
            accountType: accountType,
            upi: upi,
            ifsc: ifsc,
            pan: pan,
            branch: branch
          });
    }
    
    const getPersonalData = async () => {
        const data = await getDocs(inv_PersonalData_CollectionRef);
        const personalData = data.docs.map((doc) => ({...doc.data(), id:doc.id}));
        fillPersonalData(personalData[0]);
    }

    const getAccountData = async () => {
        const data = await getDocs(inv_AccountData_CollectionRef);
        const accountData = data.docs.map((doc) => ({...doc.data(), id:doc.id}));
        fillAccountData(accountData[0]);
    }

    function fillPersonalData(personalData) {
            setName(personalData.name);
            setEmail(personalData.email);
            setAddress1(personalData.address1);
            setAddress2(personalData.address2);
            setAddress3(personalData.address3);
            setPhone(personalData.phone);
    }

    function fillAccountData(accountData) {
            setAccountName(accountData.accountName);
            setAccountNumber(accountData.accountNumber);
            setBankName(accountData.bankName);
            setAccountType(accountData.accountType);
            setUpi(accountData.upi);
            setIfsc(accountData.ifsc);
            setPan(accountData.pan);
            setBranch(accountData.branch);
    }

    useEffect(() => {
        const auth = localStorage.getItem("auth");
        if(auth !== "Logged In")
        {
        navigate("/admin/login");
        }
      
    }, []);

  return (
    <div className='bg-gray-200'>
    <div className='flex flex-col w-8/12 mx-auto  shadow-lg border-2 p-4 bg-white gap-y-4'>
        <input className='form-input w-2/12 ml-10 rounded border border-gray-400 py-2 px-3 text-2xl font-medium tracking-wide leading-5 focus:text-gray-600' required name='name' placeholder='Invoice'  value={invoiceName} onChange={(e) => setInvoiceName(e.target.value)}/>

        <div className='flex w-full justify-evenly mt-10'>
        
            <div className='flex flex-col gap-y-4'>
            <div className='flex justify-between'>
                <div className='text-xl text-gray-600 font-medium'>Personal Information</div>
                <button onClick={getPersonalData} className='bg-[#146eb4] text-xs text-white py-2 px-4 font-semibold rounded-md text-richblack-700 hover:scale-110 transition duration-300 ease-in cursor-pointer '>Fetch Saved Personal Info</button>
            </div>
                <div className='flex gap-x-16 relative rounded-md  w-full'>
                    <div className='text-xs font-medium leading-5 text-gray-700 mt-2'>Name</div>
                    <input className='form-input block w-[300px] text-xs rounded border border-gray-400 py-2 px-4 leading-5 focus:text-gray-600' required name='name' placeholder='Business Name' value={name} onChange={(e) => setName(e.target.value)}/>
                </div>
                <div className='flex gap-x-16 relative rounded-md  w-full'>
                    <div className='text-xs font-medium leading-5 text-gray-700 mt-2'>Email</div>
                    <input className='w-full block text-xs rounded border border-gray-400 py-2 px-4 leading-5 focus:text-gray-600' required name='email' type='email' placeholder='name@business.com' value={email} onChange={(e) => setEmail(e.target.value)}/>
                </div>
                <div className='flex gap-x-12 relative rounded-md  w-full'>
                    <div className='text-xs font-medium leading-5 text-gray-700 mt-2'>Address</div>
                    <input className='w-full block text-xs rounded border border-gray-400 py-2 px-4 leading-5 focus:text-gray-600' required name='address1' placeholder='Street' value={address1} onChange={(e) => setAddress1(e.target.value)}/>
                </div>
                <input className='w-[300px] block text-xs rounded border border-gray-400 py-2 px-4 leading-5 focus:text-gray-600 ml-[95px]' required name='address2' placeholder='City, State' value={address2} onChange={(e) => setAddress2(e.target.value)}/>
                <input className='w-[300px]  block text-xs rounded border border-gray-400 py-2 px-4 leading-5 focus:text-gray-600 ml-[95px]' required name='address3' placeholder='Zip Code' value={address3} onChange={(e) => setAddress3(e.target.value)}/>
                <div className='flex gap-x-14 relative rounded-md  w-full'>
                    <div className='text-xs font-medium leading-5 text-gray-700 mt-2'>Phone</div>
                    <input className='w-full block text-xs rounded border border-gray-400 py-2 px-4 leading-5 focus:text-gray-600 ml-[2px]' required name='phone' placeholder='Phone Number' value={phone} onChange={(e) => setPhone(e.target.value)}/>
                </div>
                <button onClick={handleSave} className='bg-[#146eb4] text-sm text-white py-2 mt-12 px-4 font-semibold rounded-md text-richblack-700 hover:scale-110 transition duration-300 ease-in cursor-pointer '>Save Personal & Account Information</button>
            </div>


            <div className='flex flex-col gap-y-4'>
            <div className='flex justify-between'>
                <div className='text-xl text-gray-600 font-medium'>Account Information</div>
                <button onClick={getAccountData} className='bg-[#146eb4] text-xs text-white py-2 px-4 font-semibold rounded-md text-richblack-700 hover:scale-110 transition duration-300 ease-in cursor-pointer '>Fetch Saved Account Info</button>
            </div>
                <div className='flex gap-x-16 relative rounded-md  w-full'>
                    <div className='text-xs font-medium leading-5 text-gray-700 mt-2'>Name</div>
                    <input className='form-input block w-[300px] text-xs rounded border border-gray-400 py-2 px-4 leading-5 focus:text-gray-600' required name='name' placeholder='Your Bank Account Name' value={accountName} onChange={(e) => setAccountName(e.target.value)}/>
                </div>
                <div className='flex gap-x-6 relative rounded-md  w-full'>
                    <div className='text-xs font-medium leading-5 text-gray-700 mt-2 w-[100px]'>Bank Name</div>
                    <input className='w-full block text-xs rounded border border-gray-400 py-2 px-4 leading-5 focus:text-gray-600' required name='bankname' placeholder='HDFC Bank' value={bankName} onChange={(e) => setBankName(e.target.value)}/>
                </div>
                <div className='flex gap-x-6 relative rounded-md  w-full'>
                    <div className='text-xs font-medium leading-5 text-gray-700 mt-2 w-[100px]'>Account #</div>
                    <input className='w-full block text-xs rounded border border-gray-400 py-2 px-4 leading-5 focus:text-gray-600' required name='accountnumber' placeholder='00771...' value={accountNumber} onChange={(e) => setAccountNumber(e.target.value)}/>
                </div>
                <div className='flex  rounded-md  w-full'>
                    <div className='text-xs font-medium leading-5 text-gray-700 mt-2 w-[150px]'>Account Type</div>
                    <input className='w-full -ml-3 block text-xs rounded border border-gray-400 py-2 px-4 leading-5 focus:text-gray-600' required name='accounttype' placeholder='Saving' value={accountType} onChange={(e) => setAccountType(e.target.value)}/>
                </div>
                <div className='flex  rounded-md  w-full'>
                    <div className='text-xs font-medium leading-5 text-gray-700 mt-2 w-[150px]'>IFSC Code</div>
                    <input className='w-full -ml-3 block text-xs rounded border border-gray-400 py-2 px-4 leading-5 focus:text-gray-600' required name='accounttype' placeholder='HDFC00...' value={ifsc} onChange={(e) => setIfsc(e.target.value)}/>
                </div>
                <div className='flex  rounded-md  w-full'>
                    <div className='text-xs font-medium leading-5 text-gray-700 mt-2 w-[150px]'>Branch</div>
                    <input className='w-full -ml-3 block text-xs rounded border border-gray-400 py-2 px-4 leading-5 focus:text-gray-600' required name='branch' placeholder='Bapu Nagar...' value={branch} onChange={(e) => setBranch(e.target.value)}/>
                </div>
                <div className='flex gap-x-6 relative rounded-md  w-full'>
                    <div className='text-xs font-medium leading-5 text-gray-700 mt-2 w-[100px]'>PAN #</div>
                    <input className='w-full block text-xs rounded border border-gray-400 py-2 px-4 leading-5 focus:text-gray-600' required name='pan' placeholder='DME...' value={pan} onChange={(e) => setPan(e.target.value)}/>
                </div>

                <div className='flex gap-x-6 relative rounded-md  w-full'>
                    <div className='text-xs font-medium leading-5 text-gray-700 mt-2 w-[100px]'>UPI #</div>
                    <input className='w-full block text-xs rounded border border-gray-400 py-2 px-4 leading-5 focus:text-gray-600' required name='upi' placeholder='80955...' value={upi} onChange={(e) => setUpi(e.target.value)}/>
                </div>

            </div>
        </div>
        <hr className='w-full mt-4'></hr>
        <div className='text-xl text-gray-600 font-medium'>Invoice To</div>
        <div className='flex w-full justify-evenly'>
        
            <div className='flex flex-col gap-y-4'>
           
                <div className='flex gap-x-16 relative rounded-md  w-full'>
                    <div className='text-xs font-medium leading-5 text-gray-700 mt-2'>Name</div>
                    <input className='form-input block w-[300px] text-xs rounded border border-gray-400 py-2 px-4 leading-5 focus:text-gray-600' required name='name' placeholder='Business Name' value={inv_name} onChange={(e) => setInv_Name(e.target.value)}/>
                </div>
                <div className='flex gap-x-16 relative rounded-md  w-full'>
                    <div className='text-xs font-medium leading-5 text-gray-700 mt-2'>Email</div>
                    <input className='w-full block text-xs rounded border border-gray-400 py-2 px-4 leading-5 focus:text-gray-600' required name='email' type='email' placeholder='name@business.com' value={inv_email} onChange={(e) => setInv_Email(e.target.value)}/>
                </div>
                <div className='flex gap-x-12 relative rounded-md  w-full'>
                    <div className='text-xs font-medium leading-5 text-gray-700 mt-2'>Address</div>
                    <input className='w-full block text-xs rounded border border-gray-400 py-2 px-4 leading-5 focus:text-gray-600' required name='address1' placeholder='Street' value={inv_address1}  onChange={(e) => setInv_Address1(e.target.value)}/>
                </div>
                <input className='w-[300px] block text-xs rounded border border-gray-400 py-2 px-4 leading-5 focus:text-gray-600 ml-[95px]' required name='address2' placeholder='City, State' value={inv_address2} onChange={(e) => setInv_Address2(e.target.value)}/>
                <input className='w-[300px]  block text-xs rounded border border-gray-400 py-2 px-4 leading-5 focus:text-gray-600 ml-[95px]' required name='address3' placeholder='Zip Code' value={inv_address3} onChange={(e) => setInv_Address3(e.target.value)}/>
                <div className='flex gap-x-14 relative rounded-md  w-full'>
                    <div className='text-xs font-medium leading-5 text-gray-700 mt-2'>Phone</div>
                    <input className='w-full block text-xs rounded border border-gray-400 py-2 px-4 leading-5 focus:text-gray-600 ml-[2px]' required name='phone' placeholder='Phone Number' value={inv_phone} onChange={(e) => setInv_Phone(e.target.value)}/>
                </div>
            </div>


            <div className='flex flex-col gap-y-4'>
                <div className='flex gap-x-16 relative rounded-md  w-full'>
                    <div className='text-xs font-medium leading-5 text-gray-700 mt-2'>GST #</div>
                    <input className='form-input block w-[300px] text-xs rounded border border-gray-400 py-2 px-4 leading-5 focus:text-gray-600' required name='gst' placeholder='097665...' value={inv_gst} onChange={(e) => setInv_Gst(e.target.value)} />
                </div>
                <div className='flex gap-x-6 relative rounded-md  w-full'>
                    <div className='text-xs font-medium leading-5 text-gray-700 mt-2 w-[100px]'>PAN #</div>
                    <input className='w-full block text-xs rounded border border-gray-400 py-2 px-4 leading-5 focus:text-gray-600' required name='panbrand' placeholder='CDGC...' value={inv_pan} onChange={(e) => setInv_Pan(e.target.value)}/>
                </div>
                <div className='flex gap-x-6 relative rounded-md  w-full'>
                    <div className='text-xs font-medium leading-5 text-gray-700 mt-2 w-[100px]'>TIN #</div>
                    <input className='w-full block text-xs rounded border border-gray-400 py-2 px-4 leading-5 focus:text-gray-600' required name='tin' placeholder='00771...' value={inv_tin} onChange={(e) => setInv_Tin(e.target.value)}/>
                </div>
                <div className='flex  rounded-md  w-full'>
                    <div className='text-xs font-medium leading-5 text-gray-700 mt-2 w-[150px]'>CIN #</div>
                    <input className='w-full -ml-3 block text-xs rounded border border-gray-400 py-2 px-4 leading-5 focus:text-gray-600' required name='cin' placeholder='80955...' value={inv_cin} onChange={(e) => setInv_Cin(e.target.value)}/>
                </div>
                

            </div>
        </div>

        <hr className='w-full mt-4'></hr>

        <div className='flex relative rounded-md w-full'>
            <div className='text-xs font-medium leading-5 text-gray-700 mt-2 ml-10'>Invoice #</div>
            <input className='ml-10 w-[300px] block text-xs rounded border border-gray-400 py-2 px-4 leading-5 focus:text-gray-600' required name='invoiceNumber' placeholder='INV0001'  value={invoiceNumber} onChange={(e) => setInvoiceNumber(e.target.value)}/>
        </div>
        <div className='flex gap-x-14 relative rounded-md  w-full'>
            <div className='text-xs font-medium leading-5 text-gray-700 mt-2 ml-12'>Date</div>
            <input className='w-[300px] block text-xs rounded border border-gray-400 py-2 px-4 leading-5 focus:text-gray-600' required name='phone' placeholder='Date' type='date' value={date} onChange={(e) => setDate(e.target.value)}/>
        </div>

        <hr className='w-full mt-4'></hr>

        
        <div className='flex justify-evenly w-full uppercase text-sm'>
            <div className=''>Description</div>
            <div className='flex gap-x-20'>
                <div className=''>Rate</div>
                <div className=''>Quantity</div>
                <div className=''>Amount</div>
                <div></div>
                
            </div>
        </div>

        <hr className='w-full  border-black'></hr>
        {rows.map((row, index) => (
       
        
          <div key={index}>

          <div className='flex justify-evenly w-full uppercase  text-sm'>
          
          <input className='w-[250px] block text-xs rounded border border-gray-400 py-2 px-4 leading-5 focus:text-gray-600' required name='desc' placeholder='Reel' value={row.desc} onChange={(e) => handleInputChange(e, index)}/>
            <div className='flex gap-x-16 '>
                <input className='w-[100px] block text-xs rounded border border-gray-400 py-2 px-4 leading-5 focus:text-gray-600' required name='rate' placeholder='15000' value={row.rate} onChange={(e) => handleInputChange(e, index)}/>
                <input className='w-[80px] block text-xs rounded border border-gray-400 py-2 px-4 leading-5 focus:text-gray-600' required name='quantity' placeholder='2' value={row.qty} onChange={(e) => handleInputChange(e, index)}/>
                <div className='w-[100px]  text-black text-xs mt-3 '>{row.amount}</div>
                <div>
                <button className='border-2 px-2 py-1 rounded-md bg-gray-700 text-white font-bold mt-2' onClick={handleAddRow}>+</button>
                { rows.length > 1 && <button className='border-2 px-2 py-1 rounded-md bg-gray-700 text-white font-bold mt-2 ' onClick={e => handleDeleteRow(index,e)}>x</button>}
                </div>
            </div>
            
          </div>
          <hr className='w-full mt-4'></hr>
        
       
        </div>
    ))}

       

        <hr className='w-full border-black'></hr>

       

        <hr className='w-full '></hr>

        <div className='flex gap-x-14 ml-10 rounded-md justify-end  w-11/12'>
            <div className='text-md font-bold leading-5 text-gray-700 mt-2 '>Total</div>
            <div className='w-[100px] ml-8 text-black text-md font-bold leading-5 mt-2' name='amount'> {amount} </div>
        </div>
        
        <hr className='w-full '></hr>
        <div className='flex justify-end'>
          <button onClick={handleCreateInvoice} className='bg-[#146eb4] text-white py-2 px-4 font-semibold rounded-md text-richblack-700 hover:scale-110 transition duration-300 ease-in cursor-pointer '>Create Invoice</button>
        </div>

    </div>
</div>
  )
}

export default EditInvoice